


























































































































































































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import { Reports, User, ProjectStreams, Props, Global, Projects, DeviceStreams } from '@/store';
import API, { Types } from '@/modules/API';
import Utils from '@/modules/Utils';
import PropUtils from '@/modules/PropUtils';
import OverviewInfo from './components/OverviewInfo.vue';
import MQTT from '@/modules/MQTT';
import cloneDeep from 'lodash/cloneDeep';
import { isEqual } from 'lodash';

@Component({
  components: {
    OverviewInfo
  }
})
export default class CabinetManagement extends Vue {
  @Global.State('lang') lang;
  @Global.State('timezone') projectTimezone;
  @Global.State('dusk_dawn_data') dusk_dawn_data;
  @Global.State('mqtt_version_by_class') mqtt_version_by_class;
  @Global.State('selected_managed_area') selected_managed_area;
  @Global.Mutation('setSelectedManagedArea') setSelectedManagedArea;
  @Global.Mutation('setPageTitle') setPageTitle;
  @Reports.State('reportsList') devices;
  @Reports.State('devicesCount') devicesCount;
  @Reports.State('cabinetsCount') cabinetsCount;
  @Reports.Getter('commissionedCabinets_map') commissionedCabinets_map;
  @Reports.Getter('commissioned_map') commissioned_map;
  @Reports.Getter('commissionedCabinets') commissionedCabinets;
  @Reports.Getter('cabinets_fixtures') cabinets_fixtures;
  @Props.State('cabinet_meta_device_options') cabinet_meta_device_options;
  @User.State('project') project;
  @User.State('username') username;
  @ProjectStreams.State('next_activation_by_cabinet') next_activation_by_cabinet;
  @ProjectStreams.State('relativeStreamHistory') relativeStreamHistory;
  @ProjectStreams.State('digital_input_by_cabinet') digital_input_by_cabinet;
  @Projects.State('alerts') projects_alerts;

  alerts = 0;
  fixture_for_overview = 0;
  cabinets_alerts = {};
  mqtt = MQTT.instance;
  isLoading = true;
  cabinetsList = [];
  meta_device_fields = [];
  managed_area_map = new Map();
  filtered_managed_area_map = new Map();
  selectedValues = [];
  search_by = {};
  search = '';
  filteredCabinets = [];
  focused = true;
  panel_is_open = {};
  flex_settings = { 
    'Cabinet ID': {he: 'md2', en: 'md2'}, 
    'Controlled Fixtures': {he: 'md3', en: 'md3'},
    'Services': {he: 'md3', en: 'md3'},
    'Operation': {he: 'md2', en: 'md2'},
    'Monitor': {he: 'md2', en: 'md2'}
  };
  disabled_color = 'rgba(0,0,0,0.12)';
  devices_status = {};
  inputs_status = {};
  outputs_status = {};
  listener_ids_versions = new Map(); // holds device id as key and its version as value
  first_loading = true;
  utils_module = Utils;
  loading_project_devices = true;
  is_fetched_by_id = {};

  created() {
    this.setPageTitle('Cabinets');
    this.mqtt.init();
    this.setCabinetAlerts();
    this.generatePageData();
  }

  mounted(){
    this.$nextTick(() => {
      this.setDefaultPanelOpen();
    });
    this.setIsLoading();
    if (this.devices.length === this.devicesCount) {
      this.loading_project_devices = false;
    }
  }

  setIsLoading(){
    this.isLoading = this.cabinetsCount === -1;
  }

  generatePageData(){
    if (!this.commissionedCabinets.length) return;
    if (!this.first_loading && this.devicesCount !== this.devices.length) return;

    this.first_loading = false;
    this.cabinetsList = cloneDeep(this.commissionedCabinets);
    this.setDevicesMetadata();
    this.setConnectedAndActive();
    this.dividCabinetsByManagedArea();

    this.filtered_managed_area_map = this.managed_area_map;
    this.setCabinetDataOptions();
    this.$nextTick(() => {
      const input = this.$refs.search_combobox['$el'].querySelector('input');
      if (input) {
        input.addEventListener('keydown', this.onKeydown);
      }
    });
  }

  @Watch('cabinetsCount')
  updatePageData(){
    this.setIsLoading();
    this.generatePageData();
    this.setDefaultPanelOpen();
  }

  @Watch('devicesCount')
  @Watch('devices')
  setConnectedAndActive(){
    if (this.devicesCount !== this.devices.length) return;

    this.loading_project_devices = false;
    this.cabinetsList.forEach((cabinet) => {
      const cabinet_controllers = this.cabinets_fixtures.get(cabinet.id) || [];
      cabinet.controlled_fixtures_count = cabinet_controllers.length;
      cabinet.connected_fixtures_percentage = cabinet_controllers.length
        ? Math.ceil((this.getConnectedPercentage(cabinet_controllers) / cabinet.controlled_fixtures_count) * 100)
        : 0;
    });
   
  }

  get headers(){
    return ['Cabinet ID', 'Controlled Fixtures', 'Services', 'Operation', 'Monitor'];
  }

  dividCabinetsByManagedArea(){
    this.cabinetsList.forEach((cabinet) => {
      cabinet.linked_managed_area
        ? this.managed_area_map.get(cabinet.linked_managed_area).push(cabinet)
        : this.managed_area_map.get('Other').push(cabinet);
    });

    if (!this.managed_area_map.get('Other').length) this.managed_area_map.delete('Other');
    [...this.managed_area_map.values()].forEach((managed_area) => managed_area.sort((m1, m2) => m1.cabinet_id.localeCompare(m2.cabinet_id, undefined, {numeric: true, sensitivity: 'base'})));
  }

  setDefaultPanelOpen(){
    if (this.managed_area_map.size === 1) {
      const managed_area = [...this.managed_area_map.keys()][0];
      Vue.set(this.panel_is_open, managed_area, true);
    }else {
      [...this.managed_area_map.keys()].forEach((managed_area) => Vue.set(this.panel_is_open, managed_area, false));
    }
    if (this.selected_managed_area && !this.panel_is_open[this.selected_managed_area]) {
      this.setPanelIsOpen(this.selected_managed_area);
      const managed_area_element = document.getElementById(`${this.selected_managed_area}-panel`);
      if (managed_area_element) {
        managed_area_element.scrollIntoView();
      }
    }
  }

  setPanelIsOpen(managed_area){
    Vue.set(this.panel_is_open, managed_area, !this.panel_is_open[managed_area]);
  }

  setCabinetDataOptions(){
    this.meta_device_fields = Object.keys(this.cabinet_meta_device_options);
  }

  replaceSpecialChars(field_name){
    return field_name.includes('_') 
      ? /^[a-zA-Z]+/.test(this.$t(field_name)) 
      ? this.$t(field_name) 
      : field_name.replaceAll('_', ' ')
      : field_name;
  }
  
  @Watch('projects_alerts')
  setCabinetAlerts() {
    this.cabinets_alerts = this.projects_alerts[this.project.id] && this.projects_alerts[this.project.id].cabinets || {};
  }

  onKeydown(event) {
    if (event.key === 'Backspace') {
      const original_search_by = cloneDeep(this.search_by);
      if (this.selectedValues.length && this.selectedValues.length % 2 === 0) {
        const selected_fields = this.selectedValues
          .filter((data) => this.meta_device_fields.includes(data.value))
          .map((data) => data.value);

        const not_selected = [];

        Object.keys(this.search_by).forEach((field) => {
          if (!selected_fields.includes(field)) {
            not_selected.push(field);
          }
        });

        not_selected.forEach((not_selected_field) => delete this.search_by[not_selected_field]);
      }else if (!this.selectedValues.length){
        this.search_by = {};
      }
      if (!isEqual(original_search_by, this.search_by)) {
        this.selectedChanged();
      }
    }
  }

  @Watch('selectedValues.length')
  get items(){
    if ((this.selectedValues.length === 1 && !this.meta_device_fields.includes(this.selectedValues[0].value))
      || (this.selectedValues.length > 1 && !this.meta_device_fields.includes(this.selectedValues[0].value))
      || (this.selectedValues.length % 2 !== 0 && !this.meta_device_fields.includes(this.selectedValues[this.selectedValues.length - 1].value))) return this.selectedValues;
    const evenLength = this.selectedValues.length % 2 === 0;
    const field = evenLength ? '' : this.selectedValues.slice(-1)[0].value;
    const values = evenLength
      ? this.meta_device_fields
      : this.cabinet_meta_device_options[this.selectedValues.slice(-1)[0].value];
    const final = evenLength 
      ? values.map((value, index) => ({text: this.$t(PropUtils.getFieldLowerToUpper(value)), value, id: index}))
      : values.map((value, index) => ({text: this.$t(value), value, id: `${field}-${index}`}));

    return final.sort((data1, data2) => data1.text.toString().localeCompare(data2.text.toString(), undefined, {numeric: true, sensitivity: 'base'}));
  }

  @Watch('search')
  updateFreeSearch(){
    if (this.search && !this.selectedValues.length && !this.meta_device_fields.some((field) => field.includes(this.search))){
      this.filterByFreeSearch();
    }else if (!this.selectedValues.length && !this.selected_managed_area){
      if (!isEqual(this.filtered_managed_area_map, this.managed_area_map)) {
        this.filtered_managed_area_map = this.managed_area_map;
      }
      const panel_open = this.managed_area_map.size === 1 ? true : false;
      [...this.managed_area_map.keys()].forEach((managed_area) => Vue.set(this.panel_is_open, managed_area, panel_open));
    }else if (this.selected_managed_area){
      this.setSelectedManagedArea('');
    }
  }

  @Watch('panel_is_open', {deep: true, immediate: true})
  fetchRealCabinetsData(){
    const open_managed_area = Object.entries(this.panel_is_open)
      .filter(([managed_area, is_open]) => is_open)
      .map(([managed_area, is_open]) => managed_area);

    const managed_area_cabinets = open_managed_area.map((managed_area) => this.managed_area_map.get(managed_area)).flat();

    if (managed_area_cabinets.length) {
      this.fetchData(managed_area_cabinets);
    }
  }

  selectedChanged(){
    const last_index = this.selectedValues.length - 1;
    if (last_index >= 0) {
      const last_value = this.selectedValues[last_index];
      if (this.search && (this.selectedValues.length === 1 
        || this.meta_device_fields.includes(this.selectedValues[0].value) 
        || (this.selectedValues.length % 2 !== 0 && !this.meta_device_fields.includes(this.search)))){
        this.selectedValues[last_index] = typeof last_value === 'string' 
          ? {text: last_value, value: last_value, id: `search-${last_index}`}
          : last_value;
      }
    }

    this.search = '';

    if (this.selectedValues.length && !this.meta_device_fields.includes(this.selectedValues[0].value)) {
      if (this.selectedValues.length > 1) this.selectedValues = this.selectedValues.slice(0, 1);
      return;
    }

    this.selectedValues.map((selected) => selected.value).forEach((value, index) => {
      index % 2 === 0
        ? this.search_by[value] = '' 
        : this.search_by[this.selectedValues[index - 1].value] = value;
    });

    this.filtered_managed_area_map = new Map();
    [...this.managed_area_map.entries()].forEach(([managed_area, devices]) => {
      let area_devices = devices;
      Object.entries(this.search_by)
        .filter(([field, value]) => value)
        .forEach(([field, value]) => {
          area_devices = area_devices.filter((device) => {
            const cabinet_field_value = field === 'cabinet_type' && !device.meta_device[field] ? 'Regular' : device.meta_device[field];
            if (!cabinet_field_value) return false;
            return value.toString() === cabinet_field_value.toString();
          });
        });
      this.filtered_managed_area_map.set(managed_area, area_devices);
      if (area_devices.length) {
        Vue.set(this.panel_is_open, managed_area, Object.keys(this.search_by).length ? true : false);
      }else {
        this.filtered_managed_area_map.delete(managed_area);
        Vue.set(this.panel_is_open, managed_area, false);
      }
    });
  }

  filterByFreeSearch(){
    this.filtered_managed_area_map = new Map();
    [...this.managed_area_map.entries()].forEach(([managed_area, devices]) => {
      const matched_results = devices.filter((device) => (device.cabinet_id !== 'N/A' && device.cabinet_id === this.search) || device.description === this.search);
      this.filtered_managed_area_map.set(managed_area, matched_results);
      if (matched_results.length) {
        Vue.set(this.panel_is_open, managed_area, true);
      }else {
        Vue.set(this.panel_is_open, managed_area, false);
        this.filtered_managed_area_map.delete(managed_area);
      }
    });
  }

  removeSelectedField(index){
    if (this.selectedValues.length === 1 && !this.meta_device_fields.includes(this.selectedValues[0].value)){
      this.selectedValues = [];
      this.filtered_managed_area_map = this.managed_area_map;
    }else {
      delete this.search_by[this.selectedValues[index].value];
      this.selectedValues = this.selectedValues[index] && this.selectedValues[index + 1]
        ? this.selectedValues.slice(0, index).concat(this.selectedValues.slice(index + 2))
        : this.selectedValues.slice(0, index);
      this.selectedChanged();
    }
  }

  async fetchData(cabinetsList) {
    const realCabinets = cabinetsList.filter((device) => {
      return device.cabinet_type === 'regular' && !this.devices_status[device.id];
    });

    const slices = Math.ceil(realCabinets.length / 2);
    let startIndex = 0, endIndex = Math.min(realCabinets.length, 2);
    for (let i = 0; i < slices; i++) {
      const current = realCabinets.slice(startIndex, endIndex);
      await Promise.all(current.map((device) => this.fetchDeviceData(device)));

      startIndex = endIndex;
      endIndex = Math.min(realCabinets.length, endIndex + 2);
    }
  }

  async fetchDeviceData(device){
    if (!this.project || !this.project.id || !this.$route.path.includes('cabinet-management') || this.is_fetched_by_id[device.id]) return;
    
    Vue.set(this.is_fetched_by_id, device.id, true);
    
    device.linked_inputs = [];
    device.linked_outputs = [];
    device.schedule = {};
    const version = this.mqtt_version_by_class.has(device.class_name)
      ? this.mqtt_version_by_class.get(device.class_name).mqtt_version
      : 3;

    if (Utils.hasExtentionedTeltonicaClass(device.class_name)){
      device.child = await this.fetchCabinetChildrens(device.id);
      if (device.child){
        device.schedule = await this.fetchDeviceSchedule(device.child.id);
        const connections = await this.fetchDeviceConnections(device.child.id);
        this.setLinkedInputsOutputs(connections, device);
        device.linked_inputs.sort((input1, input2) => input1.input_num - input2.input_num);
        device.linked_outputs.sort((output1, output2) => output1.output_num - output2.output_num);
        await this.fetchExtentionStreams(device, device.child.id);
        this.listener_ids_versions.set(device.child.id, version);
        this.listenToChild(device, device.child.id, version);
      }
    }else {
      device.schedule = await this.fetchDeviceSchedule(device.id);
      const connections = await this.fetchDeviceConnections(device.id);
      device.display_new_connections_screen = Utils.displayNewConnectionsScreen(device['device.version'], connections);
      let input_num_field = 'gpio_address';
      let output_num_field = 'gpio_address';
      if (device.display_new_connections_screen) {
        this.setLinkedInputsOutputsNewFormat(connections, device);
      }else {
        this.setLinkedInputsOutputs(connections, device);
        input_num_field = 'input_num';
        output_num_field = 'output_num';
      }
      device.linked_inputs.sort((input1, input2) => input1[input_num_field] - input2[input_num_field]);
      device.linked_outputs.sort((output1, output2) => output1[output_num_field] - output2[output_num_field]);
      await this.fetchCabinetStreams(device, input_num_field, output_num_field);
      this.listener_ids_versions.set(device.id, version);
      this.listenToMqtt(device, version);
    }
    Vue.set(this.devices_status, device.id, true);
  }

  async fetchExtentionStreams(parent, child_id){
    await Promise.all(parent.linked_inputs.map(async (input) => {
      let status = await this.fetchStreamLatestValue(child_id, `input_${input.input_num}`) || 0;
      status = typeof status === 'string' ? Number(status) : status;
      Vue.set(this.inputs_status[parent.id], input.input_num, status ? true : false);
    }));

    await Promise.all(parent.linked_outputs.map(async (output) => {
      let status = await this.fetchStreamLatestValue(child_id, `relay_${output.output_num}`) || 0;
      status = typeof status === 'string' ? Number(status) : status;
      Vue.set(this.outputs_status[parent.id], output.output_num, status ? true : false);
    }));
  }

  checkInputIsOpposite(config){
    return config.toLowerCase().includes('1=tripped') || config.toLowerCase().includes('1=off');
  }

  checkOutputIsOpposite(config){
    return config.toLowerCase().includes('1=off');
  }

  getInputColor(input_config, status){
    const config_is_opposite = this.checkInputIsOpposite(input_config);
    const input_is_on = config_is_opposite ? !status : status;
    return input_is_on ? '#7FB77E' : '#E97777';
  }

  getInputColorNewFormat(status){
    return status ? '#7FB77E' : '#E97777';
  }

  getOutputStatus(output_config, status){
    if (typeof status !== 'boolean') return status;
    const config_is_opposite = this.checkOutputIsOpposite(output_config);
    return config_is_opposite ? !status : status;
  }

  getOutputStatusNewFormat(output_config, status){
    if (typeof status !== 'boolean') return status;
    const config_is_opposite = false;
    return config_is_opposite ? !status : status;
  }

  async fetchCabinetStreams(device, input_num_field, output_num_field){
    let digital_input = await this.fetchStreamLatestValue(device.id, 'digital_input') || {};
    try {
      digital_input = JSON.parse(Utils.convertToValidJson(digital_input));
    }catch (e){
      digital_input = {};
    }
    let control = await this.fetchStreamLatestValue(device.id, 'control') || {};
    try {
      control = JSON.parse(Utils.convertToValidJson(control));
    }catch (e){
      control = {};
    }
    device.linked_inputs.forEach((input) => {
      const status = digital_input[`${input[input_num_field]}`] || false;
      Vue.set(this.inputs_status[device.id], input[input_num_field], status);
    });
    device.linked_outputs.forEach((output) => {
      const status = control[`${output[output_num_field]}`] || false;
      Vue.set(this.outputs_status[device.id], output[output_num_field], status);
    });
  }

  async fetchCabinetChildrens(device_id){
    const childrens_id = await API.get(Types.PROJECTS, this.project.id + '/devices/' + device_id + '/children/');
    // childrens = childrens.filter((children) => Utils.hasExtentionCardClass(children.class_name));
    const child_device_id = childrens_id && childrens_id.length && childrens_id[0] || '';
    return child_device_id 
      ? this.devices.find((device) => device.id === child_device_id)
      : null;
  }

  setLinkedInputsOutputsNewFormat(connections, device){
    if (Array.isArray(connections)) {
      device.linked_inputs = connections.filter((connection) => connection.type === 'input');
      device.linked_outputs = connections.filter((connection) => connection.type === 'output')
        .map((output) => ({...output, ...Utils.getTodayScheduleTime(device.schedule, output.gpio_address)}))
        .filter((output) => output.on_time);
    }else {
      device.linked_inputs = [];
      device.linked_outputs = [];
    }
  }

  setLinkedInputsOutputs(connections, device){
    Object.values(connections).forEach((connection) => {
      const elements = connection['elements'] && Object.values(connection['elements']);
      this.getChildInputOutput(elements, device, connection['desc']);
    });
  }

  getChildInputOutput(elements, device, connection_description){
    elements.forEach((element) => {
      if (element['control']) {
        device.linked_outputs.push({ output_num: element['control'], configuration: element.configuration, on_time: this.getTime('on', device, element['control']) , off_time: this.getTime('off', device, element['control']) });
      }else {
        device.linked_inputs.push({ input_num: element['input'], description: connection_description, configuration: element.configuration });
      }
      if (!element.hasOwnProperty('children')) return;
      if (element.hasOwnProperty('children') && element['children'].length) this.getChildInputOutput(element['children'], device, connection_description);
    });
  }

  setDevicesMetadata(){
    const area_map = new Map();
    this.cabinetsList.forEach((device) => {
      Vue.set(this.devices_status, device.id, false);
      Vue.set(this.inputs_status, device.id, {});
      Vue.set(this.outputs_status, device.id, {});

      device.meta_device = this.commissionedCabinets_map.has(device.id) && this.commissionedCabinets_map.get(device.id)['meta.device'];
      device.cabinet_id = device.meta_device && device.meta_device.cabinet_id || 'N/A';
      device.description = device.meta_device && device.meta_device.description || '';
      device.cabinet_type = Utils.hasVirtualCabinetClass(device.class_name, device['meta.device']) && 'virtual' || 'regular';
      device.managed_area = device.meta_device && (device.meta_device.managed_area || device.meta_device.road || '') || '';
      device.linked_managed_area = device.managed_area ? this.getLinkedManagedArea(device.managed_area) : '';
      device.dashboard_view = PropUtils.parseProperty('dashboard.connection_display', device);
      device.analytics_alerts_status = Utils.getAnalyticsAlertsServiceStatus(device, this.projectTimezone);
      device.devices_schedule_status = Utils.getCabinetDevicesSchedule(device, this.projectTimezone);
      device.power_validation_status = Utils.getPowerValidationServiceStatus(device);
      device.cabinet_alerts_status = Utils.getCabinetSatecAlertsServiceStatus(device);
      if (device.linked_managed_area && !area_map.has(device.linked_managed_area)) area_map.set(device.linked_managed_area, []);
    });
    this.managed_area_map = new Map([...area_map.entries()].sort((m1, m2) => m1[0].localeCompare(m2[0], undefined, {numeric: true, sensitivity: 'base'})));
    this.managed_area_map.set('Other', []);
  }

  getConnectedPercentage(controlled_devices){
    return controlled_devices.filter((device) => device.sys___active === 1 && device.sys___connected === 1).length;
  }

  getLinkedManagedArea(managed_area){
    let ma = managed_area.toString();
    if (ma.toLowerCase().includes('road') || ma.toLowerCase().includes('כביש') || ma.includes('/') || ma.match(/\\/)){
      let final_ma = '';
      ma = ma.toLowerCase();
      if (ma.includes('road')){
        final_ma = 'Road ';
        ma = ma.replace('road', '');
      }else if (ma.includes('כביש')){
        final_ma = 'כביש ';
        ma = ma.replace('כביש', ''); 
      }
      ma = ma.trim();
      if (this.isNumeric(ma[0])){
        if (ma.match(/\\/)){
          ma = ma.split('\\')[0];
        }else if (ma.includes('/')){
          ma = ma.split('/')[0];
        }
        let index = 1;
        let numeric = true;
        while (index < 4 && numeric && index < ma.length){
          numeric = this.isNumeric(ma[index]);
          if (numeric) index++;
        }
        return final_ma + ma.slice(0, index);
      }else return 'Other';
    }
    return ma.trim();
  }

  isNumeric(character){
    return /\d/.test(character);
  }

  async fetchDeviceConnections(device_id){
    let device_connections = await this.fetchSingleProperty(device_id, 'device.connections');
    device_connections = device_connections && device_connections.value || {};
    return device_connections;
  }

  async fetchDeviceSchedule(device_id){
    let device_schedule = await this.fetchSingleProperty(device_id, 'device.schedule');
    device_schedule = device_schedule && device_schedule.value || {};
    return device_schedule;
  }

  async fetchSingleProperty(device_id, property_name) {
    try {
      const response = await API.get(Types.DEVICES, `${device_id}/properties`, { name: property_name });
      return (response && response.results && response.results[0]) || null;
    } catch (e) {
      return null;
    }
  }

  async fetchStreamLatestValue(device_id, stream_name) {
    try {
      const response = await API.get(Types.DEVICES, `${device_id}/streams/${device_id}.${stream_name}/latest`);
      return (response && response.lastvalue) || null;
    } catch (e) {
      return null;
    }
  }

  moveToCabinetDetails(device, selected_managed_area) {
    this.setSelectedManagedArea(selected_managed_area);
    this.$router.push(`/cabinet-details/${device.id}`);
  }

  getCabinetStatusIcon(device) {
    let src = require('@/assets/images/mapView_icons/cabinet/cabinet_fault.svg');
    try {
      if (device['sys___active'] === 0) src = require('@/assets/images/mapView_icons/cabinet/cabinet_fault.svg');
      else if (device['sys___connected'] === 1) src = require('@/assets/images/cabinet_details_icons/cabinet_connected.svg');
      else if (device['sys___connected'] === 0) src = require('@/assets/images/cabinet_details_icons/cabinet_disconnected.svg');
    } catch (err) {}
    return src;
  }

  listenToMqtt(device, version) {
    if (!this.mqtt || !device) return;

    const topic = this.mqtt.getSingleDeviceSubscribe(device.id, version);

    this.mqtt.listen(topic, async (msg, match) => {
        const mqtt_msg_data = this.mqtt.getMqttMsgData(msg, match, version);

        if (mqtt_msg_data.message_type === 'evt' && 
          mqtt_msg_data.stream_name && mqtt_msg_data.format === 'json' &&
          (mqtt_msg_data.stream_name === 'control' || mqtt_msg_data.stream_name === 'digital_input')
        ){
          if (mqtt_msg_data.stream_name === 'control') {        
            this.updateOutputs(mqtt_msg_data.message, device);
          }else {
            this.updateInputs(mqtt_msg_data.message, device);
          }
        }
      }
    );

    this.publishMqttMessage('-1', 'control', device);
    this.publishMqttMessage('-1', 'digital_input', device);
  }

  listenToChild(parent, child_id, version) {
    if (!this.mqtt || !child_id) return;

    this.mqtt.listen(
      this.mqtt.getSingleDeviceSubscribe(child_id, version),
      async (msg, match) => {
        const mqtt_msg_data = this.mqtt.getMqttMsgData(msg, match, version);

        if (mqtt_msg_data.message_type === 'evt' &&
          mqtt_msg_data.stream_name && 
          (mqtt_msg_data.stream_name.includes('input_') || mqtt_msg_data.stream_name.includes('relay_'))
        ){
          if (mqtt_msg_data.stream_name.includes('input_')){
            const input_num = this.getInputOutputNumber(mqtt_msg_data.stream_name);
            if (input_num && input_num >= 1 && input_num <= 12){
              Vue.set(this.inputs_status[parent.id], input_num, Number(mqtt_msg_data.message) ? true : false);
            }
          }else {
            const output_num = this.getInputOutputNumber(mqtt_msg_data.stream_name);
            if (output_num && output_num >= 1 && output_num <= 4){
              Vue.set(this.outputs_status[parent.id], output_num, Number(mqtt_msg_data.message) ? true : false);
            }
          }
        }
      }
    );
  }

  getInputOutputNumber(stream_name){
    const [start, num] = stream_name.split('_');
    return !isNaN(num) && Number(num) || '';
  }

  publishMqttMessage(message, streamName, device) {
    const mqtt_publish_data = {
      version_num: 3,
      device_id: device.id,
      stream_id: '',
      message_type: 'cmd',
      format: 'minimal',
      stream_value: message,
      options: {
        retain: false,
        qos: 1
      }
    };

    if (this.mqtt_version_by_class.has(device.class_name)) {
      mqtt_publish_data.version_num = this.mqtt_version_by_class.get(device.class_name).mqtt_version;
    }

    mqtt_publish_data.stream_id = mqtt_publish_data.version_num === 3 ? `${device.id}.${streamName}` : streamName;
    this.mqtt.publishToSingleDevice(mqtt_publish_data);
  }

  getValidJson(message) {
    let parsed_message;
    try{
      parsed_message = JSON.parse(message);
    }catch (e){
      parsed_message = { content: ''};
    }
    return parsed_message;
  }

  mqttGetMsg(format, message) {
    return format === 'json' ? message : format === 'minimal' ? Number(message) : Number(message['content']);
  }

  clearAllClicked(){
    this.search_by = {};
    this.filtered_managed_area_map = this.managed_area_map;
  }

  updateOutputs(msg, device) {
    const control_stream = JSON.parse(JSON.stringify(msg));
    Object.entries(control_stream).forEach(([output, value]) => {
      const output_num = Number(output);
      if (this.outputs_status[device.id].hasOwnProperty(output_num)) Vue.set(this.outputs_status[device.id], output_num, value);
    });
  }

  updateInputs(msg, device) {
    const digital_input_stream = JSON.parse(JSON.stringify(msg));

    Object.entries(digital_input_stream).forEach(([input, value]) => {
      const input_num = Number(input);
      if (this.inputs_status[device.id].hasOwnProperty(input_num)) {
        Vue.set(this.inputs_status[device.id], input_num, value);
      }
    });
  }

  getTitleOperation(device, output) {
    const schedule = device.schedule[`${output}`] || null;
    if (schedule && schedule['is_astronomical'] === true) return 'Astral';
    if (schedule && schedule['is_astronomical'] === false) return 'Clock';
    return 'N/A';
  }

  getTime(onOff, device, output) {
    if (this.next_activation_by_cabinet.has(device.id)) {
      const time = this.getNextActivationTime(onOff, device.id, output);
      if (time !== null) return time;
    }

    const schedule = device.schedule[`${output}`] || null;
    let time = '';
    if (schedule && schedule['is_astronomical'] === true){
      if (onOff === 'on'){
        time = schedule.dusk;
        time = Utils.getAstronomicalTime(time, this.dusk_dawn_data.sunset_start_timestamp, this.dusk_dawn_data.sunset_start, this.projectTimezone);
      }else{
        time = schedule.dawn;
        time = Utils.getAstronomicalTime(time, this.dusk_dawn_data.sunrise_timestamp, this.dusk_dawn_data.sunrise, this.projectTimezone);
      }
    }else {
      time = schedule && schedule[onOff] || '';
    }
    return time;
  }

  getNextActivationTime(onOff, device_id, output){
    const next_activation_times = this.next_activation_by_cabinet.get(device_id)
        .filter(([timestamp, value]) => value && value.output === output);

    if (next_activation_times.length) {
      const time = next_activation_times[next_activation_times.length - 1][1][onOff === 'on' ? 'on_time' : 'off_time'];
      return time.split(' ')[1].slice(0, 5);
    }else return null;
  }

  beforeDestroy() {
    [...this.listener_ids_versions.entries()].forEach(([device_id, version]) => {
      const device_topic = this.mqtt.getSingleDeviceSubscribe(device_id, version);
      this.mqtt.unlisten(device_topic);
    });
    this.mqtt.clearListeners();
    const input = this.$refs.search_combobox['$el'].querySelector('input');
    if (input) {
      input.removeEventListener('keydown', this.onKeydown);
    }
  }
}
