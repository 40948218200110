var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.loading)?_c('v-card',{staticClass:"pa-4",attrs:{"color":"transparent","flat":""}},[_c('v-layout',{staticClass:"info-containers-styled-overview"},[_c('v-flex',{class:['pa-2', 'blue-10-rounded-card'],staticStyle:{"background-color":"white"},attrs:{"xs4":""}},[_c('v-layout',{staticClass:"mb-3"},[_c('img',{class:['icon-style', _vm.lang === 'en' ? 'mr-2' : 'ml-2'],attrs:{"src":require('@/assets/images/tondo_icons/cabinet_color.svg')}}),_c('h2',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t('CABINET')))])]),_vm._l((Object.entries(_vm.cabinetStatus)),function(ref){
var status = ref[0];
var count = ref[1];
return _c('v-layout',{key:status,attrs:{"justify-center":"","align-center":""}},[_c('v-layout',{class:_vm.lang === 'en' ? 'pl-2' : 'pr-2',staticStyle:{"width":"60%"}},[_c('span',{staticClass:"font-weight-bold",class:[_vm.lang === 'en' ? 'mr-1' : 'ml-1']},[_vm._v(_vm._s(_vm.$t(status))+":")]),_c('span',{staticClass:"font-weight-bold indigo--text"},[_vm._v(_vm._s(count))])]),_c('v-layout',{staticClass:"progress-bar-div"},[_c('v-flex',{staticClass:"mx-2",attrs:{"d-flex":""}},[_c('v-progress-linear',{staticStyle:{"margin":"0","border-radius":"9px"},attrs:{"value":(count/_vm.cabinets.length)*100,"height":"12","color":"#7E57C2","rounded":""}})],1)],1)],1)})],2),_c('v-flex',{staticClass:"pa-2 blue-10-rounded-card mx-3",staticStyle:{"background-color":"white"},attrs:{"xs4":""}},[_c('v-layout',{staticClass:"mb-3"},[_c('img',{class:['icon-style', _vm.lang === 'en' ? 'mr-2' : 'ml-2'],attrs:{"src":require('@/assets/images/tondo_icons/bell_color.svg')}}),_c('h2',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t('ALERTS')))])]),_vm._l((Object.entries(_vm.alerts_by_priority)),function(ref){
var priority = ref[0];
var count = ref[1];
return _c('v-layout',{key:priority,attrs:{"justify-center":"","align-center":""}},[_c('v-layout',{class:_vm.lang === 'en' ? 'pl-2' : 'pr-2',staticStyle:{"width":"60%"},attrs:{"align-center":""}},[_c('div',{staticClass:"square_alert",class:[_vm.lang === 'en' ? 'mr-2' : 'ml-2', priority === 'Low' ? 'green_alert' : priority === 'Medium' ? 'yellow_alert' : 'red_alert']}),_c('span',{staticClass:"font-weight-bold",class:[_vm.lang === 'en' ? 'mr-1' : 'ml-1']},[_vm._v(_vm._s(_vm.$t(priority))+":")]),_c('span',{staticClass:"font-weight-bold indigo--text"},[_vm._v(_vm._s(count))])])],1)}),_c('v-layout',{key:"info-count",attrs:{"justify-center":"","align-center":""}},[_c('v-layout',{class:_vm.lang === 'en' ? 'pl-2' : 'pr-2',staticStyle:{"width":"60%"},attrs:{"align-center":""}},[_c('div',{staticClass:"info_alert square_alert",class:[_vm.lang === 'en' ? 'mr-2' : 'ml-2']}),_c('span',{staticClass:"font-weight-bold",class:[_vm.lang === 'en' ? 'mr-1' : 'ml-1']},[_vm._v(_vm._s(_vm.$t('Info'))+":")]),_c('span',{staticClass:"font-weight-bold indigo--text"},[_vm._v(_vm._s(_vm.events_count))])])],1)],2),_c('v-flex',{class:['pa-2', 'blue-10-rounded-card'],staticStyle:{"background-color":"white"},attrs:{"xs4":""}},[_c('v-layout',{staticClass:"mb-3"},[_c('img',{class:['icon-style', _vm.lang === 'en' ? 'mr-2' : 'ml-2'],attrs:{"src":require('@/assets/images/cabinet_details_icons/sunset_color.svg')}}),_c('h2',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t('NEXT OPERATION')))])]),_vm._l((Object.entries(_vm.next_operation)),function(ref){
var title = ref[0];
var count = ref[1];
return _c('v-layout',{key:title,attrs:{"justify-center":"","align-center":""}},[_c('v-layout',{class:_vm.lang === 'en' ? 'pl-2' : 'pr-2',staticStyle:{"width":"60%"}},[_c('span',{staticClass:"font-weight-bold",class:[_vm.lang === 'en' ? 'mr-1' : 'ml-1']},[_vm._v(_vm._s(_vm.$t(title))+":")]),_c('span',{staticClass:"font-weight-bold indigo--text"},[_vm._v(_vm._s(count))])])],1)})],2)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }