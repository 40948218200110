<template>
  <v-card v-if="!loading" color="transparent" flat class="pa-4">
    <v-layout class="info-containers-styled-overview"> 
      <v-flex xs4 style="background-color: white; " :class="['pa-2', 'blue-10-rounded-card']">
        <v-layout class="mb-3">
          <img :src="require('@/assets/images/tondo_icons/cabinet_color.svg')" :class="['icon-style', lang === 'en' ? 'mr-2' : 'ml-2']">
          <h2 class="font-weight-bold">{{ $t('CABINET') }}</h2>
        </v-layout>
        <v-layout v-for="[status, count] in Object.entries(cabinetStatus)" :key="status" justify-center align-center>
          <v-layout :class="lang === 'en' ? 'pl-2' : 'pr-2'" style="width: 60%">
            <span class="font-weight-bold" :class="[lang === 'en' ? 'mr-1' : 'ml-1']">{{ $t(status) }}:</span>
            <span class="font-weight-bold indigo--text">{{ count }}</span>
          </v-layout>
          <v-layout class="progress-bar-div">
            <v-flex d-flex class="mx-2">
              <v-progress-linear
                :value="(count/cabinets.length)*100"
                height="12"
                color="#7E57C2"
                style="margin: 0; border-radius: 9px;"
                rounded
              >
              </v-progress-linear>
            </v-flex>
          </v-layout>
        </v-layout>
      </v-flex>
      <v-flex xs4 style="background-color: white;" class="pa-2 blue-10-rounded-card mx-3">
        <v-layout class="mb-3">
          <img :src="require('@/assets/images/tondo_icons/bell_color.svg')" :class="['icon-style', lang === 'en' ? 'mr-2' : 'ml-2']">
          <h2 class="font-weight-bold">{{ $t('ALERTS') }}</h2>
        </v-layout>
        <v-layout v-for="[priority, count] in Object.entries(alerts_by_priority)" :key="priority" justify-center align-center>
          <v-layout :class="lang === 'en' ? 'pl-2' : 'pr-2'" style="width: 60%" align-center>
            <div class="square_alert" :class="[lang === 'en' ? 'mr-2' : 'ml-2', priority === 'Low' ? 'green_alert' : priority === 'Medium' ? 'yellow_alert' : 'red_alert']"></div>
            <span class="font-weight-bold" :class="[lang === 'en' ? 'mr-1' : 'ml-1']">{{ $t(priority) }}:</span>
            <span class="font-weight-bold indigo--text">{{ count }}</span>
          </v-layout>
        </v-layout>
         <v-layout key="info-count" justify-center align-center>
          <v-layout :class="lang === 'en' ? 'pl-2' : 'pr-2'" style="width: 60%" align-center>
            <div class="info_alert square_alert" :class="[lang === 'en' ? 'mr-2' : 'ml-2']"></div>
            <span class="font-weight-bold" :class="[lang === 'en' ? 'mr-1' : 'ml-1']">{{ $t('Info') }}:</span>
            <span class="font-weight-bold indigo--text">{{ events_count }}</span>
          </v-layout>
        </v-layout>
      </v-flex>
      <v-flex xs4 style="background-color: white; " :class="['pa-2', 'blue-10-rounded-card']">
        <v-layout class="mb-3">
          <img :src="require('@/assets/images/cabinet_details_icons/sunset_color.svg')" :class="['icon-style', lang === 'en' ? 'mr-2' : 'ml-2']">
          <h2 class="font-weight-bold">{{ $t('NEXT OPERATION') }}</h2>
        </v-layout>
        <v-layout v-for="[title, count] in Object.entries(next_operation)" :key="title" justify-center align-center>
          <v-layout :class="lang === 'en' ? 'pl-2' : 'pr-2'" style="width: 60%">
            <span class="font-weight-bold" :class="[lang === 'en' ? 'mr-1' : 'ml-1']">{{ $t(title) }}:</span>
            <span class="font-weight-bold indigo--text">{{ count }}</span>
          </v-layout>
        </v-layout>
      </v-flex>
    </v-layout>
  </v-card>
</template>

<script>
import Utils from '@/modules/Utils';

export default {
  name: 'OverviewInfo',
  props: {
    devices: {
      type: Array,
      default: []
    },
    lang: {
      type: String,
      default: 'en'
    },
    dusk_dawn_data: {
      type: Object,
      default: {}
    },
    fixture_for_overview: {
      type: Number,
      default: 0
    },
    cabinets_alerts: {
      type: Object,
      default: {}
    }
  },
  data() {
    return {
      cabinetStatus: {Active: 0, Inactive: 0, Maintenance: 0, Virtual: 0},
      next_operation: {},
      loading: true
    };
  },
  computed: {
    cabinets() {
      this.$set(this.cabinetStatus, 'Active', 0);
      this.$set(this.cabinetStatus, 'Inactive', 0);
      this.$set(this.cabinetStatus, 'Maintenance', 0);
      this.$set(this.cabinetStatus, 'Virtual', 0);

      this.$store.getters['Reports/commissionedCabinets'].forEach((cabinet) => this.setCabinetStatus(cabinet));

      return this.$store.getters['Reports/commissionedCabinets'];
    },
    alerts_by_priority() {
      const alerts_map = {High: 0, Medium: 0, Low: 0};
      if (this.cabinets_alerts && Object.keys(this.cabinets_alerts).length) {
        Object.values(this.cabinets_alerts).forEach((cabinet_alerts_data) => {
          const priority_text = cabinet_alerts_data.top_priority === 1
            ? 'Low'
            : cabinet_alerts_data.top_priority === 2
              ? 'Medium'
              : 'High';
          alerts_map[priority_text] = alerts_map[priority_text] + 1;
        });
      }
      return alerts_map;
    },
    project_id(){
      return this.$store.state.User.project.id;
    },
    events_count() {
      return Object.keys(this.$store.state.Projects.events).length && this.$store.state.Projects.events[this.project_id] 
        ? Utils.getProjectTicketsList(this.$store.state.Projects.events[this.project_id]).length
        : 0;
    }
  },
  mounted(){
    this.loading = true;
    this.next_operation['Project Sunset Time'] = this.dusk_dawn_data.sunset_start.format('hh:mm A');
    this.next_operation['Project Sunrise Time'] = this.dusk_dawn_data.sunrise.format('hh:mm A');
    this.loading = false;
  },
  methods: {
    setCabinetStatus(cabinet) {
      const key = Utils.hasVirtualCabinetClass(cabinet.class_name, cabinet['meta.device'])
        ? 'Virtual'
        : cabinet.sys___active === 0
          ? 'Inactive'
          : cabinet.sys___active === -1
            ? 'Maintenance'
            : 'Active';

      this.$set(this.cabinetStatus, key, this.cabinetStatus[key] + 1);
    }
  }
};
</script>

<style scoped>

.device-overview {
  background-color: #F6F8FB;
  color: #140F49;
}

.device-overview__column {
  text-align: left;
  display: grid;
}

.device-overview__column-heading {
  margin-bottom: 10px;
  font-size: 16px;
}

.border-right {
  border-right: 6px solid #CFD8DC;
}

.border-left {
  border-left: 6px solid #CFD8DC;
}

.blue--text {
  color: #0057ff !important;
}

.padding-right {
  padding-right: 30px;
}

.padding-right-15 {
  padding-right: 15px;
}


.padding-right-7 {
  padding-right: 7px;
}

.padding-left {
  padding-left: 30px;
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}

.icon-overview {
  max-height: 35px;
  float: left;
  padding-right: 1vh;
}

.device-overview span {
  font-size: 14px;
}

.progress-bar-div {
  white-space: nowrap; 
  width: 40%; 
  display: flex; 
  align-items: center; 
  text-align: center;
  padding: 0;
  margin: 0;
}

.info-containers-styled-overview {
  overflow-x: auto;
}

.v-progress-linear {
  margin: 0;
}

.purple-progress-bar {
  background-color: #7E57C2;
}

.grey-progress-bar {
  background-color: #EDEEF4;
}

.dir-rtl {
  direction: rtl;
  text-align: right;
}

.alerts-cell-div {
  max-height: 60px;
  overflow: auto;
}

.icon-style {
  max-height: 35px;
}
</style>
